<template>
<div class="sm:block hidden">
  <el-dialog v-model="errorNoSubscribe" title="Aucun abonnement" width="40%" center :before-close="closeNoErrorSubscribeModal" :close-on-click-modal=false	 destroy-on-close>
    
      <el-result
        icon="warning"
        title="Vous avez aucun abonnement"
        sub-title="Le contenu que vous avez accès est un contenu gratuit veuillez activer un abonnement pour bénificier de la totalité des questions"
      >
      </el-result>

    <template #footer>
      <span class="dialog-footer">
        <router-link to="/dashboard/subscriptions" class="py-2 px-4 my-2 text-white font-normal text-base bg-red-400 rounded-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Activer un abonnement</router-link>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block" >
  <el-dialog v-model="errorNoSubscribe" title="Aucun abonnement" width="85%" center :before-close="closeNoErrorSubscribeModal" :close-on-click-modal=false destroy-on-close>
    
      <el-result
        icon="warning"
        title="Vous avez aucun abonnement"
        sub-title="Le contenu que vous avez accès est un contenu gratuit veuillez activer un abonnement pour bénificier de la totalité des questions"
      >
      </el-result>

    <template #footer>
      <span class="dialog-footer">
       <router-link to="/dashboard/subscriptions" class="py-2 px-4 my-2 text-white font-normal text-base bg-red-400 rounded-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Activer un abonnement</router-link>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>

export default {
  
  props:{
    errorNoSubscribe : Boolean,
    closeNoErrorSubscribeModal: Function,
  },

}
</script>

<style>

</style>